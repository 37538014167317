<template>
    <table class="links_table text-left flex-1 h-full w-full">
      <thead class="text-[#757A8A] bg-[#F4F6FA] font-semibold text-[0.875rem] font-poppins">
      <tr>
        <td class="py-3 pl-3 text-left w-[20%]">Referral</td>
        <td class="py-3 text-center w-[20%]"
            @click.prevent="referral_sorting = (referral_sorting === 'clicks_asc') ? 'clicks_desc': 'clicks_asc'">
          Clicks
          <i v-if="referral_sorting==='clicks_asc'"
             class="first table_filter_caret fas fa-caret-up"></i>
          <i v-if="referral_sorting==='clicks_desc'"
             class="table_filter_caret fas fa-caret-down"></i>
        </td>
        <td class="py-3 text-center w-[20%]"
            @click.prevent="referral_sorting = (referral_sorting === 'unique_clicks_asc') ? 'unique_clicks_desc': 'unique_clicks_asc'">
          Unique Clicks
          <i v-if="referral_sorting==='unique_clicks_asc'"
             class="first table_filter_caret fas fa-caret-up"></i>
          <i v-if="referral_sorting==='unique_clicks_desc'"
             class="table_filter_caret fas fa-caret-down"></i>
        </td>
        <td class="py-3 text-center w-[20%]"
            @click.prevent="referral_sorting = (referral_sorting === 'conversions_asc') ? 'conversions_desc': 'conversions_asc'">
          Conversions
          <i v-if="referral_sorting==='conversions_asc'"
             class="first table_filter_caret fas fa-caret-up"></i>
          <i v-if="referral_sorting==='conversions_desc'"
             class="table_filter_caret fas fa-caret-down"></i>
        </td>
        <td class="py-3 text-center w-[20%]">
          Conv. rate
        </td>
      </tr>
      </thead>
      <tbody class="list-content-body">
      <tr v-if="statistics.aggregations.visited_from_domains.buckets.length == 0">
        <td colspan="9">
          <div class="col">
            <p class="no-rows text-center">
              <no-events-available></no-events-available>
            </p>
          </div>
        </td>
      </tr>
      <template v-else>
        <tr v-for="analytic in getVisitedFromList" class="border-b border-[#ECEEF5] ">
          <td class="w-[20%] text-left pl-3 py-4">
            {{analytic.key}}
          </td>
          <td class="w-[20%] text-center py-4">{{analytic.doc_count}}</td>
          <td class="w-[20%] text-center py-4">{{analytic.unique_clicks.value}}</td>
          <td class="w-[20%] text-center py-4">
            {{analytic.clicked_conversions.doc_count}}
          </td>
          <td class="w-[20%] text-center py-4">
            {{Math.round((analytic.clicked_conversions.doc_count / analytic.doc_count)*100)}}%
          </td>
        </tr>
      </template>
      </tbody>
    </table>
</template>
<script>
import orderBy from 'lodash/orderBy'

export default ({
  data () {
    return {
      referral_sorting: 'clicks_desc'
    }
  },
  props: {
    statistics: {}
  },
  computed: {
    getVisitedFromList () {
      if (this.statistics.aggregations.visited_from_domains.buckets.length > 0) {
        const sortingByArray = this.sortingValue(this.referral_sorting)
        let results = orderBy(this.statistics.aggregations.visited_from_domains.buckets, [sortingByArray[0]], [sortingByArray[1]])
        return results
      }
      return []
    }
  }
})

</script>
